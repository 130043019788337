import Const from '../constants'
import store from '@/store'
import axiosApiInstance from '@/interceptors'

class SettingService {
  getUserSetting() {
    return axiosApiInstance.get(Const.api_url + 'members/me/member-settings')
  }

  getTitleColor() {
    return axiosApiInstance.get(Const.api_url + 'system-setting/2')
  }

  getTitleBgColor() {
    return axiosApiInstance.get(Const.api_url + 'system-setting/1')
  }

  getThemes() {
    return axiosApiInstance.get(Const.api_url + 'system-setting/3')
  }

  updateUserSetting(data: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/member-settings', data)
  }
}

export default new SettingService
