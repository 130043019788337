







































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import Setting from '@/store/modules/Setting'
import SettingService from '@/services/SettingService'
//@ts-ignore
import _ from 'lodash'
const SettingModule = getModule(Setting, store)

@Component
export default class SettingNotifyNews extends Vue {
  private showMsg: boolean = false
  private confirmChange: any
  private hours_options: any[] = []
  private notify_options: any[] = [
    {
      id: 1,
      value: 1,
      text: this.$t('setting.setting_notify_news.notify')
    },
    {
      id: 2,
      value: 0,
      text: this.$t('setting.setting_notify_news.no_notify')
    }
  ]
  private notify_interval_options: any[] = [
    { id: 1, value: 15 / 60 },
    { id: 2, value: 30 / 60 },
    { id: 3, value: 1 },
    { id: 4, value: 2 },
    { id: 5, value: 3 },
    { id: 6, value: 6 },
    { id: 7, value: 12 },
    { id: 8, value: 24 }
  ]

  private notify_news: any = {
    regular_stop_notify_start: '',
    regular_stop_notify_end: '',
    first_email_regular_option: '',
    second_email_regular: '',
    second_email_regular_option: '',
    third_email_regular: '',
    third_email_regular_option: '',
    email_notify_time: '',
    schedule_email_option: '',
    notify_interval: '',
    first_email_emergency_option: '',
    second_email_emergency: '',
    second_email_emergency_option: '',
    third_email_emergency: '',
    third_email_emergency_option: '',
    emergency_stop_notify_start: '',
    emergency_stop_notify_end: '',
    last_send_mail_regular: ''
  }

  created() {
    this.getHours()
    this.getSettingNotifyNews()
  }

  getRegularError() {
    return [
      (this.$refs as any).regularStopNotifyStart?.errors[0],
      (this.$refs as any).regularStopNotifyEnd?.errors[0]
    ].filter(item => item)
  }

  getEmergencyError() {
    return [
      (this.$refs as any).emergencyStopNotifyStart?.errors[0],
      (this.$refs as any).emergencyStopNotifyEnd?.errors[0]
    ].filter(item => item)
  }

  getSettingNotifyNews() {
    this.notify_news = _.cloneDeep(
      store.state.setting.setting.setting_notify_news
    )
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (
      !_.isEqual(
        this.notify_news,
        store.state.setting.setting.setting_notify_news
      )
    ) {
      this.confirmChange = next
      this.$bvModal.show('modal-confirm')
    } else {
      next()
    }
  }

  confirm() {
    this.confirmChange()
  }

  getHours() {
    for (let i = 0; i < 24; i++) {
      this.hours_options.push({
        id: i + 1,
        value: i
      })
    }
    let optionDisabled = {
      id: -1,
      value: null
    }
    this.hours_options.unshift(optionDisabled)
  }

  changeNotifyNews() {
    let setting_data = Object.assign({}, store.state.setting.setting)
    setting_data.setting_notify_news = this.notify_news
    SettingService.updateUserSetting(setting_data)
      .then(response => {
        if (response.status === 200) {
          let setting_notify_news = response.data.setting_notify_news
          SettingModule.CHANGE_NOTIFY_NEWS(setting_notify_news)
          this.$bvModal.show('modal-success')
        }
      })
      .catch(error => {
        this.$bvModal.show('modal-error')
      })
      .finally(() => {
        this.getSettingNotifyNews()
      })
  }
}
